import debounce from 'lodash/debounce';

let fixedVhEls = document.querySelectorAll('.fixed100vh');

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
fixedVhEls.forEach((fixedEl) => {
    fixedEl.style.setProperty('--vh', `${vh}px`);
});

function redoOnResize() {
    let vh = window.innerHeight * 0.01;
    fixedVhEls.forEach((fixedEl) => {
        fixedEl.style.setProperty('--vh', `${vh}px`);
    });
}

window.addEventListener('resize', debounce(redoOnResize), 150);
