import shave from 'shave';
import debounce from 'lodash/debounce';

let $sliderProdotto = $('.schedaprodotto .flexslider');

if ($sliderProdotto.length > 0) {
    $sliderProdotto.flexslider({
        animation: 'slide',
        controlNav: 'thumbnails',
        directionNav: false,
    });
}
