import shave from 'shave';
import debounce from 'lodash/debounce';

var fontSize;

let productNames = document.querySelectorAll('.productgrid__link');

function trimNames() {
    if (productNames.length > 0) {
        productNames.forEach((productName) => {
            let productNameCss = window.getComputedStyle(productName);
            fontSize = productNameCss.fontSize;
        });
        shave(productNames, fontSize.slice(0, -2) * 3, { spaces: false });
    }
    console.log('Nome trimmato');
}

trimNames();

function trimDescriptions() {
    let categoryDescriptions = document.querySelectorAll('.productgrid__categoryDescription');
    if (categoryDescriptions.length > 0) {
        categoryDescriptions.forEach((categoryDescription) => {
            let categoryDescriptionCss = window.getComputedStyle(categoryDescription);
            fontSize = categoryDescriptionCss.fontSize;
        });
        shave(categoryDescriptions, fontSize.slice(0, -2) * 3, { spaces: false });
    }
    console.log('Descrizione trimmata');
}

trimDescriptions();

window.addEventListener('resize', debounce(function() {
    trimNames();
    trimDescriptions();
}, 150));

// Click su tutto il div
let divs = document.querySelectorAll('.productgrid__item');

divs.forEach((div) => {
    div.addEventListener('click', () => {
        let link = div.querySelector('a');
        let href = link.getAttribute('href');

        window.location = href;
    });
});
