import { closeDrawer, closeNav } from './nav';
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { hasScrollbar } from '../util/hasScrollbar';

let siteWrapper = document.querySelector('.siteWrapper');
let searchButton = document.querySelector('.topbar__button_search');
let closeButton = document.querySelector('.searchpanel__closer');
let searchButtonDesktop = document.querySelector('.featureNav__item_search');
let searchPanel = document.querySelector('.searchpanel');
let searchInput = searchPanel.querySelector('input');

let panelTiming = 320;
let transitionTiming = 240;

function openSearchPanel() {
    let topbar = document.querySelector('.topbar');
    let topbarH = topbar.clientHeight;
    let icone = searchButton.querySelectorAll('svg');
    let results = document.querySelector('.searchpanel__results');

    // Se siamo in mobile
    searchButton.addEventListener('click', function (e) {
        // Funzionalità mobile
        if (siteWrapper.classList.contains('siteWrapper_hasMobileNav') && !this.classList.contains('topbar__button_clicked')) {
            icone.forEach((icona) => {
                icona.style.transitionDuration = `${transitionTiming / 1000}s`;
            });

            searchButton.classList.add('topbar__button_clicked');
            searchPanel.classList.add('searchpanel_isOpen');
            searchPanel.style.height = `calc(100% - ${topbarH}px)`;
            searchPanel.style.maxHeight = `calc(100% - ${topbarH}px)`;
            searchPanel.style.top = `${topbarH}px`;
            searchPanel.style.display = 'block';
            searchInput.focus();

            closeDrawer();

            disableBodyScroll(results);
        } else if (siteWrapper.classList.contains('siteWrapper_hasMobileNav') && this.classList.contains('topbar__button_clicked')) {
            closeSearchPanel();
        }
    });
    // Funzionalità desktop
    searchButtonDesktop.addEventListener('click', () => {
        if (siteWrapper.classList.contains('siteWrapper_hasDesktopNav') && !searchPanel.classList.contains('searchpanel_isOpen')) {
            searchPanel.style.display = 'block';
            searchPanel.style.transitionDuration = `${panelTiming / 1000}s`;
            disableBodyScroll(results);

            setTimeout(() => {
                searchPanel.classList.add('searchpanel_isOpen');
                searchInput.focus();
            }, 80);
            closeSearchPanel();
        }
    });
}

openSearchPanel();

function closeSearchPanel() {
    if (siteWrapper.classList.contains('siteWrapper_hasMobileNav')) {
        searchButton.classList.remove('topbar__button_clicked');
        searchPanel.removeAttribute('style');
        searchPanel.classList.remove('searchpanel_isOpen');
        searchInput.value = '';
        clearAllBodyScrollLocks();
    }
    closeButton.addEventListener('click', () => {
        if (siteWrapper.classList.contains('siteWrapper_hasDesktopNav')) {
            searchPanel.classList.remove('searchpanel_isOpen');
            searchInput.value = '';
            clearAllBodyScrollLocks();

            setTimeout(() => {
                searchPanel.removeAttribute('style');
                searchPanel.style.display = 'none';
            }, panelTiming);
        }
    });
}

function resetSearchPanel() {
    searchButton.classList.remove('topbar__button_clicked');
    searchPanel.classList.remove('searchpanel_isOpen');
    searchPanel.removeAttribute('style');
}

export { closeSearchPanel, resetSearchPanel };
