import debounce from 'lodash/debounce';
import shave from 'shave';

let bannerTexts = document.querySelectorAll('.bannergrid__text');
var fontSize;

function trimNames() {
    if (bannerTexts.length > 0) {
        bannerTexts.forEach((bannerText) => {
            let bannerTextsCss = window.getComputedStyle(bannerText);
            fontSize = bannerTextsCss.fontSize;
        });

        shave(bannerTexts, fontSize.slice(0, -2) * 5, { spaces: false });
    }
}

trimNames();

window.addEventListener('resize', debounce(trimNames, 150));

// Click su tutto il div
let divs = document.querySelectorAll('.bannergrid__item');

divs.forEach((div) => {
    div.addEventListener('click', () => {
        let link = div.querySelector('a');
        let href = link.getAttribute('href');

        window.location = href;
    });
});
