import PerfectScrollbar from 'perfect-scrollbar';

const popup = document.querySelector('.popup');
const popupContainer = document.querySelector('.popup__container');
const closer = document.querySelector('.popup__closer');

if (popup !== null) {
    const time = popup.getAttribute('data-time') * 1000

    setTimeout(() => {
        openModal();
    }, time);

    closer.addEventListener('click', function () {
        closeModal();
    });

    document.addEventListener('click', function (event) {
        if (!popupContainer.contains(event.target) && popup.classList.contains('popup_open')) {
            closeModal();
        }
    })
}


function openModal() {
    document.body.classList.add('body_modalOpen')
    const ps = new PerfectScrollbar(popupContainer);
    popup.style.display = 'block';
    popup.classList.add('popup_opening');
    popupContainer.addEventListener('animationend', () => {
        popup.classList.remove('popup_opening');
        popup.classList.add('popup_open');
    })

}

function closeModal() {
    popup.classList.remove('popup_open');
    popup.classList.add('popup_closing');
    popup.addEventListener('animationend', () => {
        popup.style.display = 'none';
        popup.classList.remove('popup_closing');
        document.body.classList.remove('body_modalOpen')
    })
}

