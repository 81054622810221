let inputFiles = document.querySelectorAll('.form__el_file');

inputFiles.forEach((input) => {
    let veroInput = input.querySelector('input');
    veroInput.setAttribute('data-multiple-caption', '{count} file');
    let label = input.querySelector('label');
    let labelVal = label.innerHTML;
    let controlWrap = input.querySelector('.wpcf7-form-control-wrap');
    
    controlWrap.appendChild(label);

    input.addEventListener('change', function(e) {
        let fileName = '';
        if (this.files && this.files.length > 1) {
            fileName = ( this.getAttribute( 'data-multiple-caption') || '').replace('{count}'. this.files.length);
        } else {
            fileName = e.target.value.split( '\\' ).pop();
        }
        if (fileName) {
            label.querySelector('span').innerHTML = fileName;
        } else {
            label.innerHTML = labelVal;
        }
    })
});

