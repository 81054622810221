function dropDownFeatured() {
    let siteWrapper = document.querySelector('.siteWrapper');
    let $featureNavSublists = $('.featureNav__item > ul');
    let $featureNavLinks = $featureNavSublists.prev();
    let transitionTiming = 240;

    $featureNavLinks.on('click', function () {
        let $questaUl = $(this).next();
        let $altreUl = $featureNavLinks.not(this).next();
        let $questoLi = $(this).parent();
        let $altreLi = $featureNavLinks.not(this).parent();

        if (siteWrapper.classList.contains('siteWrapper_hasDesktopNav')) {
            if (!$questoLi.hasClass('featureNav__item_isOpen')) {
                $questaUl.css('display', 'block');
                $questaUl.css('transition-duration', `${transitionTiming / 1000}s`);

                setTimeout(() => {
                    $questoLi.addClass('featureNav__item_isOpen');
                }, 1);
            } else {
                $questoLi.removeClass('featureNav__item_isOpen');

                setTimeout(() => {
                    $questaUl.removeAttr('style');
                }, transitionTiming);
            }
            
            $altreLi.removeClass('featureNav__item_isOpen');

            setTimeout(() => {
                $altreUl.removeAttr('style');
                $altreUl.css('display', 'none');
            }, transitionTiming);
        }
    });

    document.addEventListener('click', (e) => {
        // e.stopPropagation();
        let evenTarget = e.target;
        let subMenuAperte = document.querySelectorAll('featureNav__item_isOpen ul');

        if (subMenuAperte && !evenTarget.closest('.featureNav__link')) {
            $featureNavLinks.parent().removeClass('featureNav__item_isOpen');

            setTimeout(() => {
                $featureNavSublists.removeAttr('style');
                $featureNavSublists.css('display', 'none');
            }, transitionTiming);
        }
    });
}

dropDownFeatured();
