const { closeSearchPanel, resetSearchPanel } = require('./searchpanel');
import debounce from 'lodash/debounce';

import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

let drawer = document.querySelector('.drawer');
let mainNav = document.querySelector('.mainNav');
let drawerNav = document.querySelector('.drawer__nav');
let topbar = document.querySelector('.topbar');
let $navArrow = $('.mainNav__arrow');
let navArrow = document.querySelectorAll('.mainNav__arrow');
let subLists = document.querySelectorAll('li > ul');
let topbarControls = document.querySelector('.topbar__controls');
let hamburger = document.querySelector('.topbar__hamburger');
let backButtons = document.querySelectorAll('.backButton');
let siteWrapper = document.querySelector('.siteWrapper');
let mediaQueryValue = siteWrapper.getAttribute('data-dena');
let mediaQuery = window.matchMedia(`(min-width: ${mediaQueryValue}px)`);
var darkener;

cambioQuery();

let transitionTiming = 240;

// Funzioni
// ========

// Imposta le classi sul siteWrapper
function cambioQuery() {
    // Se siamo du mobile
    if (!mediaQuery.matches && !siteWrapper.classList.contains('siteWrapper_hasMobileNav')) {
        siteWrapper.classList.add('siteWrapper_hasMobileNav');
        siteWrapper.classList.remove('siteWrapper_hasDesktopNav');
        clearAllBodyScrollLocks();
        resetSearchPanel();
        resetMobileNav();
        resetDesktopNav();
        buildMobileNav();
        // Se siamo du desktop
    } else if (mediaQuery.matches && !siteWrapper.classList.contains('siteWrapper_hasDesktopNav')) {
        siteWrapper.classList.remove('siteWrapper_hasMobileNav');
        siteWrapper.classList.add('siteWrapper_hasDesktopNav');
        clearAllBodyScrollLocks();
        resetSearchPanel();
        resetMobileNav();
        resetDesktopNav();
        buildDesktopNav();
    }
}

// Funzioni desktop
// ================

function buildDesktopNav() {
    let asideWrapper = document.querySelector('.aside__wrapper');
    let footer = document.querySelector('.footer');

    // Inserisco il menu nell'aside
    asideWrapper.insertBefore(mainNav, footer);

    // Aggiungo i data-attribute chiusi ai li con sottomenu
    subLists.forEach((sottolista) => {
        sottolista.parentElement.setAttribute('data-open', 'close');
    });
}

function resetDesktopNav() {
    let liParents = document.querySelectorAll('.mainNav [data-open]');

    // Se ci sono li parenti con il data-open, rimuovo il data-open
    liParents.forEach((liParent) => {
        if (liParent) {
            liParent.removeAttribute('data-open');
            let pulsante = liParent.querySelector('button');
            pulsante.setAttribute('aria-expanded', 'false')
        }
    });

    // Rimuovo lo stile alle sottoliste
    subLists.forEach((sottolista) => {
        sottolista.removeAttribute('style');
    });
}

function walkerDesktop() {
    let selectedLi = document.querySelector('.mainNav__item_selected');
    if (selectedLi) {
        selectedLi.setAttribute('data-open', 'open');
    }
    $navArrow.on('click', function () {
        if (siteWrapper.classList.contains('siteWrapper_hasDesktopNav')) {
            let $altreUl = $navArrow.not(this).next();
            let $questaUl = $(this).next();

            $altreUl.parent().attr('data-open', 'close');

            if ($questaUl.parent().attr('data-open') == 'close') {
                $questaUl.parent().attr('data-open', 'open');
                $(this).attr('aria-expanded', 'true');
            } else {
                $questaUl.parent().attr('data-open', 'close');
                $(this).attr('aria-expanded', 'false');
            }

            $altreUl.slideUp(320);
            $questaUl.slideToggle(320);
        }
    });
}

walkerDesktop();

// Funzioni Mobile
// ===============
function resetMobileNav() {
    let darkener = document.querySelector('.darkener');

    if (darkener) {
        darkener.remove();
    }

    // Tolgo gli stili alle sottoliste
    subLists.forEach((sottolista) => {
        sottolista.removeAttribute('style');
    });

    // Tolgo gli stili al drawer
    drawer.removeAttribute('style');

    // Tolgo gli stili al drawerNav
    drawerNav.removeAttribute('style');

    // Tolgo la classe di attivazione dell'hamburger
    hamburger.classList.remove('topbar__button_clicked');
}

function buildMobileNav() {
    // Appendo il mainNav al drawerNav
    drawerNav.appendChild(mainNav);
}

function walkerMobile() {
    subLists.forEach((sottoLista) => {
        let bottone = sottoLista.previousElementSibling;
        let linkBandiera = sottoLista.parentElement.querySelector('a');

        bottone.addEventListener('click', () => {
            if (siteWrapper.classList.contains('siteWrapper_hasMobileNav')) {
                sottoLista.style.visibility = 'visible';
                console.log(sottoLista);

                if (bottone.getAttribute('aria-expanded') == 'false') {
                    bottone.setAttribute('aria-expanded', 'true');
                }

                drawerNav.style.transform = 'translateX(-100%)';
                drawerNav.style.transitionDuration = `${transitionTiming / 1000}s`;

                setTimeout(() => {
                    drawerNav.style.visibility = 'hidden';
                }, transitionTiming);
            }
        });

        // Funzione per il click sul link con la bandiera
        linkBandiera.addEventListener('click', (e) => {
            let flag = linkBandiera.querySelector('.mainNav__flagIcon');

            if (siteWrapper.classList.contains('siteWrapper_hasMobileNav')) {
                if (linkBandiera.contains(flag)) {
                    e.preventDefault();
                    let bottoneAccanto = linkBandiera.nextElementSibling;
                    bottoneAccanto.click();
                }
            }
        });
    });

    backButtons.forEach((bottone) => {
        bottone.addEventListener('click', () => {
            
            // Tolgo l'aria expanded a tutti i bottoni del menu
            subLists.forEach(sottoLista => {
                let bottone = sottoLista.previousElementSibling;
                bottone.setAttribute('aria-expanded', 'false');
            });

            if (siteWrapper.classList.contains('siteWrapper_hasMobileNav')) {
                drawerNav.style.visibility = 'visible';
                drawerNav.style.transform = 'translateX(0)';

                setTimeout(() => {
                    subLists.forEach((sottoLista) => {
                        sottoLista.style.visibility = 'hidden';
                    });
                }, transitionTiming);
            }
        });
    });
}

walkerMobile();

// Apri drawer
function openDrawer() {
    let icone = topbarControls.querySelectorAll('svg');

    icone.forEach((icona) => {
        icona.style.transitionDuration = `${transitionTiming / 1000}s`;
    });

    hamburger.classList.add('topbar__button_clicked');

    drawerNav.style.visibility = 'visible';

    let topbarH = topbar.clientHeight;

    darkener = document.createElement('div');
    darkener.classList.add('darkener');
    siteWrapper.appendChild(darkener);

    setTimeout(() => {
        darkener.classList.add('darkener_appeared');
    }, 1);

    drawer.style.height = `calc(100% - ${topbarH}px)`;
    drawer.style.top = `${topbarH}px`;
    drawer.style.transitionDuration = `${transitionTiming / 1000}s`;
    drawer.style.transform = 'translateX(0)';

    closeSearchPanel();

    disableBodyScroll(drawerNav);
}

function closeDrawer() {
    hamburger.classList.remove('topbar__button_clicked');

    drawer.style.transform = 'translateX(101%)';

    // Torno alla posizione neutra del nav
    drawerNav.style.transform = 'translateX(0)';

    setTimeout(() => {
        drawerNav.style.visibility = 'hidden';
        drawer.removeAttribute('style');
    }, transitionTiming);

    if (darkener != null) {
        darkener.classList.remove('darkener_appeared');
        setTimeout(() => {
            darkener.remove();
        }, 321);
    }

    // Tolgo l'aria expanded a tutti i bottoni del menu
    subLists.forEach(sottoLista => {
        let bottone = sottoLista.previousElementSibling;
        bottone.setAttribute('aria-expanded', 'false');
    });

    clearAllBodyScrollLocks();
}

export { closeDrawer };

// function setAriaExpansion() {
//     navArrow.forEach((arrow) => {
//         arrow.addEventListener('click', () => {
//             navArrow.forEach((arrow) => {
//                 arrow.setAttribute('aria-expanded', 'false');
//             });
//         });
//     });
// }

// setAriaExpansion();

// Listeners
// =========
hamburger.addEventListener('click', function () {
    if (!this.classList.contains('topbar__button_clicked')) {
        openDrawer();
    } else {
        closeDrawer();
    }
});

document.addEventListener('click', (e) => {
    if (e.target && e.target.classList.contains('darkener')) {
        closeDrawer();
    }
});

window.addEventListener('resize', debounce(cambioQuery, 150));

export { mediaQueryValue, mediaQuery };
