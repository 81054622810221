// Importare i moduli
// import './modules/nomemodulo';
import './util/util';
import './polyfills/polyfills';
import './modules/nav';
import './modules/searchpanel';
import './modules/dropFeatured';
import './modules/carouselFlex';
import './modules/productgrid';
import './modules/bannergrid';
import './modules/mainEl';
import './modules/schedaprodotto';
import './modules/ranger';
import './modules/aggiustamenti';
import './modules/usermenu';
import './modules/inputfile';
import './modules/popup';

// Rimozione classe no-js
document.documentElement.className = document.documentElement.className.replace(/\bno-js\b/, 'js');