import debounce from 'lodash/debounce';

function accountForTopbar() {
    let main = document.querySelector('.main');
    let topbar = document.querySelector('.topbar');
    let topbarH = topbar.clientHeight;
    let siteWrapper = document.querySelector('.siteWrapper');

    // Se siamo su mobile
    if (siteWrapper.classList.contains('siteWrapper_hasMobileNav')) {
        main.style.paddingTop = `${topbarH}px`;
        // Se siamo du desktop
    } else if (siteWrapper.classList.contains('siteWrapper_hasDesktopNav')) {
        main.style.paddingTop = '';
    }
}

accountForTopbar();

window.addEventListener('resize', debounce(accountForTopbar, 150));
