let rangers = document.querySelectorAll('.ranger');

rangers.forEach(ranger => {
    let minus = ranger.querySelector('.ranger__button_left');
    let plus = ranger.querySelector('.ranger__button_right');
    let input = ranger.querySelector('input');

    minus.addEventListener('click', () => {
        input.stepDown(1);
    })
    plus.addEventListener('click', () => {
        input.stepUp(1);
    })
});