import shave from 'shave';
import debounce from 'lodash/debounce';

let $sliderHomepage = $('.carouselFlex.flexslider');

if ($sliderHomepage.length > 0) {
    $sliderHomepage.flexslider({
        directionNav: false,
    });
}

// Taglio testo in eccesso
let carouselTexts = document.querySelectorAll('.carouselFlex__text');
var fontSize;

function trimCarouselTitles() {
    carouselTexts.forEach((carouselText) => {
        let textCss = window.getComputedStyle(carouselText);
        fontSize = textCss.fontSize;
        shave(carouselText, fontSize.slice(0, 2) * 3, { spaces: false });
    });
}

trimCarouselTitles();

window.addEventListener('resize', debounce(trimCarouselTitles, 150));
